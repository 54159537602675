//
// Main init file of global bootstrap and theme functions, mixins, variables and config
//


// Functions
@import "base/functions";

// Mixins
@import "base/mixins";
@import "components/mixins";
@import "vendors/plugins/mixins";

// Bootstrap mixins
@import "~bootstrap/scss/mixins";

// Global variables
@import "components/variables.demo";
@import "components/variables.bootstrap";
@import "components/variables.custom";

// 3rd-Party plugins variables
@import "vendors/plugins/variables";

// Theme layout variables
@import "layout/variables";


.gyms-layout {
    display: grid;
    grid-template-columns: 25% 25% 25%;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    img {
        width: 100%;
    }

    .title {
        color: #2FE09B;
    }
}